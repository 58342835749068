import React from 'react';
import { Redirect } from '@reach/router';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { Breadcrumb } from 'antd';
import DOMPurify from 'isomorphic-dompurify';

import { StyledH6, PageContent } from './basic-styles';

import PublicLayout from '../layout/public-layout';
import theme from '../theme';
import { NavigationWrapper } from '../components/common/page-header';

type PartnersDetailsProps = {
  data: {
    partners: any;
  };
  location: any;
};

type Partner = {
  directusId: string;
  partner_slug: string;
  partner_type: string;
  partner_title: string;
  partner_about: string;
  partner_description: string;
  partner_cta_label: string;
  partner_website: string;
  display_partner_page: boolean;
  partner_logo: {
    data: {
      full_url: string;
    };
  };
};

const StyledLink = styled(Link)`
  text-transform: capitalize;
`;

const PartnersContainer = styled.div`
  max-width: ${theme.screenLgMax};
  margin: 24px auto 0;
`;

const Image = styled.img`
  display: block;
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  margin: 15px auto 45px;
`;

const StyledHeader = styled(StyledH6)`
  margin-bottom: 12px;
`;

const StyledPageContent = styled(PageContent)`
  font-size: 18px;
  color: ${theme.colorsBlack};
  margin-bottom: 50px;
`;

export const query = graphql`
  query($id: Int!) {
    partners: allDirectusPartner(filter: { directusId: { eq: $id } }) {
      nodes {
        directusId
        partner_slug
        partner_type
        partner_title
        partner_about
        partner_description
        partner_cta_label
        partner_website
        display_partner_page
        partner_logo {
          data {
            full_url
          }
        }
      }
    }
  }
`;

const PartnerDetails = ({ data: { partners }, location }: PartnersDetailsProps) => {
  const partner: Partner = partners.nodes[0];
  const prevLink = location?.state?.previousLink ? location.state.previousLink : '/';
  const breadcrumb = location?.state?.previousLink
    ? location.state.previousLink
        .split('-')
        .join(' ')
        .replace('/', '')
    : 'Home';

  if (!partner.display_partner_page) {
    return <Redirect noThrow to={'/'} />;
  }

  return (
    <PublicLayout seoTitle="Partners">
      <PartnersContainer>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            <Breadcrumb.Item>
              <StyledLink to={prevLink}>{breadcrumb}</StyledLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{partner.partner_title}</Breadcrumb.Item>
          </Breadcrumb>
        </NavigationWrapper>
        <Image src={partner.partner_logo.data.full_url} alt={partner.partner_cta_label} />
        <StyledHeader>Our Partnership</StyledHeader>
        <StyledPageContent
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(partner.partner_description),
          }}
        />
        <StyledHeader>About {partner.partner_title}</StyledHeader>
        <StyledPageContent
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(partner.partner_about),
          }}
        />
      </PartnersContainer>
    </PublicLayout>
  );
};

export default PartnerDetails;
